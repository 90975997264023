import { CookieConsent } from '@trustpilot/businessapp-browserstorage';
import Config from '../../../app/configuration';
import { initializeAnalyticsJS } from '@trustpilot/businessapp-analytics/lib/segment';
import { hashEmailForId } from '../../utils/hashEmailForId';
import configuration from '../../../app/configuration';

export class AppAnalytics {
  public async loadTrackingScript(): Promise<AppAnalytics> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const appAnalytics: AppAnalytics = this;

    if (!window.analytics) {
      initializeAnalyticsJS();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.analytics.initialized) {
      return appAnalytics;
    }

    await CookieConsent.onceConsentGiven('C0002', () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!(window.analytics && window.analytics.initialized)) {
        window.analytics.load(Config.SegmentIoKey, {
          integrations: {
            All: true,
            AdRoll: false,
            'Bing Ads': false,
            'Facebook Pixel': false,
            'Google Ads (Classic)': false,
            'Google Ads (Gtag)': false,
            'Google Tag Manager': false,
            'LinkedIn Insight Tag': false,
            'Twitter Ads': false,
          },
        });
        if (window.location.pathname.toLocaleLowerCase().includes('/changepassword')) {
          // eslint-disable-next-line no-undefined
          window.analytics.page(undefined, undefined, {
            path: '/changepassword',
            url: `${configuration.Host}/changePassword`,
          });
        } else {
          window.analytics.page();
        }
      }
    });

    return appAnalytics;
  }

  public track(eventName: string, eventData: Record<string, unknown>): Promise<void> {
    return new Promise<void>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!(window.analytics && window.analytics.initialized)) {
        // User has not given consent so analytics is not loaded
        resolve();
      } else {
        window.analytics.track(eventName, eventData, () => resolve());
      }
    });
  }

  public identify(
    email?: string,
    traits?: Record<string, unknown>,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!(window.analytics && window.analytics.initialized)) {
        // User has not given consent so analytics is not loaded
        resolve();
      } else {
        window.analytics.identify(hashEmailForId(email), traits, options, () => resolve());
      }
    });
  }
}
